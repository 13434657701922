<template>
  <div class="page-reset-password">
    page {{ title }} is working!
  </div>
</template>

<script lang="js">
import ResetPasswordPage from './reset-password';

export default ResetPasswordPage;
</script>

<style lang="scss">
@import "./reset-password";
</style>
